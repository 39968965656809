// prettier-ignore
type ServerConfigKey =
  | 'APP_WEB_SECRET'
  | 'APP_API_KEY'

// CAUTION! These constants are inlined at build time and publicly available.
// They are declared in the publicRuntimeConfig set to be available for inlining at build time.
// More on this: https://nextjs.org/docs/pages/building-your-application/configuring/environment-variables#bundling-environment-variables-for-the-browser

const publicRuntimeConfig = {
  ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
  HERMES_API_URL: process.env.NEXT_PUBLIC_HERMES_API_URL,
  LEGACY_API_URL: process.env.NEXT_PUBLIC_LEGACY_API_URL,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  ADDRESS_AUTOCOMPLETE_API_URL: process.env.NEXT_PUBLIC_ADDRESS_AUTOCOMPLETE_API_URL,
  ADDRESS_AUTOCOMPLETE_API_KEY: process.env.NEXT_PUBLIC_ADDRESS_AUTOCOMPLETE_API_KEY,
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  ENABLE_ANALYTICS: process.env.NEXT_PUBLIC_ENABLE_ANALYTICS,
  INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  POEDITOR_API_URL: process.env.NEXT_PUBLIC_POEDITOR_API_URL,
  POEDITOR_ACCESS_KEY: process.env.NEXT_PUBLIC_POEDITOR_ACCESS_KEY,
  POEDITOR_PROJECT_ID: process.env.NEXT_PUBLIC_POEDITOR_PROJECT_ID,
  POSTHOG_API_KEY: process.env.NEXT_PUBLIC_POSTHOG_API_KEY,
  POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  SEARCH_API_URL: process.env.NEXT_PUBLIC_SEARCH_API_URL,
  SEARCH_API_KEY: process.env.NEXT_PUBLIC_SEARCH_API_KEY
}

export const getPublicConfigVariable = (key: keyof typeof publicRuntimeConfig): string => {
  const value = publicRuntimeConfig[key]
  if (!value) {
    throw new Error(`Missing or empty public config variable: ${key}`)
  }
  return value
}

// These constants are not inlined at build time and are only available in the server.
export const getServerConfigVariable = (key: ServerConfigKey): string => {
  const secret = process.env[key]

  if (!secret) {
    throw new Error(`Missing server config variable: ${key}`)
  }

  return secret
}
