export enum DefaultCurrency {
  SE = 'SEK',
  NO = 'NOK'
}

export enum CurrencySymbol {
  SEK = 'Kr',
  NOK = 'Kr'
}

export const getCurrencyFromLocale = (locale: string) => {
  switch (locale) {
    case 'se': {
      return DefaultCurrency.SE
    }
    case 'no': {
      return DefaultCurrency.NO
    }
    case 'en-no': {
      return DefaultCurrency.NO
    }
    case 'en-se': {
      return DefaultCurrency.SE
    }
    default: {
      return DefaultCurrency.NO
    }
  }
}
