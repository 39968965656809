import { useLocale } from '@hooks/useLocale'
import useTranslateMessage from '@hooks/useTranslateMessage'
import { useSearchParams } from 'next/navigation'
import { useSortBy } from 'react-instantsearch'
import { extractLocaleFromPathname, Locale } from './localeHelpers'

export enum SearchIndex {
  sweden = 'books_sweden',
  norway = 'books_norway'
}

const SearchIndexMapping: { [key in Locale]: SearchIndex } = {
  [Locale.NORWEGIAN_NORWAY]: SearchIndex.norway,
  [Locale.SWEDISH_SWEDEN]: SearchIndex.sweden,
  [Locale.ENGLISH_NORWAY]: SearchIndex.norway,
  [Locale.ENGLISH_SWEDEN]: SearchIndex.sweden
}

export const getIndexFromLocale = (locale: string) => {
  const verifiedLocale = extractLocaleFromPathname(`/${locale}/`)
  const index = SearchIndexMapping[verifiedLocale]
  if (!index) {
    throw new Error(`No search index found for locale: ${locale}`)
  }
  return index
}

export const getSearchParams = (locale: string) => {
  const index = getIndexFromLocale(locale)
  return useSearchParams()?.get(`${index}[query]`) || ''
}

export enum SortByAttribute {
  RATING = 'ratingAvg:desc',
  POPULARITY = 'popularity.popularityScore360d:desc'
}

export type SortingOption = {
  value: string
  label: string
}

export const useResetSearchSorting = () => {
  const sortingOptions = getSortingOptions()
  const defaultOptionValue = sortingOptions[0]
  const { refine } = useSortBy({ items: sortingOptions })

  const resetSorting = () => {
    refine(defaultOptionValue.value)
  }
  return { resetSorting }
}

export const getSortingOptions = (): SortingOption[] => {
  const locale = useLocale()
  const index = getIndexFromLocale(locale)
  const { t } = useTranslateMessage()
  return [
    { value: index, label: `${t({ id: 'sort.relevance', defaultMessage: 'Relevance' })}` },
    {
      value: `${index}:${SortByAttribute.RATING}`,
      label: `${t({ id: 'sort.rating.desc', defaultMessage: 'Highest rating' })}`
    },
    {
      value: `${index}:${SortByAttribute.POPULARITY}`,
      label: `${t({ id: 'sort.popularity.desc', defaultMessage: 'Popularity' })}`
    }
  ]
}
