import { getPublicConfigVariable } from './getConfigVariable'

const keyWithCookieSuffix = (
  key: 'saleInfo' | 'deliveryService' | 'deliveryMethod' | 'saleBookId' | 'token' | 'royaltyValue' | 'giftCardCode',
  locale: string
) => {
  const env = getPublicConfigVariable('ENVIRONMENT')

  const mergeSuffix = (value: string) => {
    if (env === 'development') return `${key}-dev-${value}`
    if (env === 'staging') return `${key}-staging-${value}`
    return `${key}-${value}`
  }

  switch (locale) {
    case 'se': {
      return mergeSuffix('se')
    }
    case 'no': {
      return mergeSuffix('no')
    }
    case 'en-se': {
      return mergeSuffix('se')
    }
    case 'en-no': {
      return mergeSuffix('no')
    }
    default: {
      return 'no'
    }
  }
}

export default keyWithCookieSuffix
