import { useLocale } from '@hooks/useLocale'
import keyWithCookieSuffix from '@lib/utils/keyWithCookieSuffix'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import { useCallback } from 'react'

const useUserToken = () => {
  const locale = useLocale()
  const cookieName = keyWithCookieSuffix('token', locale)
  const token = getCookie(cookieName)

  const deleteToken = useCallback(() => {
    deleteCookie(cookieName)
  }, [cookieName])

  const setToken = useCallback(
    (value: string) => {
      setCookie(cookieName, value)
    },
    [cookieName]
  )

  return {
    token,
    deleteToken,
    setToken
  }
}

export { useUserToken }
