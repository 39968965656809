import { Locale } from './localeHelpers'

export enum Region {
  SWEDEN = 'se',
  NORWAY = 'no'
}

function isLocale(value: any): value is Locale {
  return Object.values(Locale).includes(value)
}

const getRegionFromLocale = (locale: string) => {
  if (!isLocale(locale)) {
    console.error('Invalid locale', locale)
    return Region.NORWAY
  }

  switch (locale) {
    case Locale.SWEDISH_SWEDEN:
    case Locale.ENGLISH_SWEDEN:
      return Region.SWEDEN
    case Locale.NORWEGIAN_NORWAY:
    case Locale.ENGLISH_NORWAY:
      return Region.NORWAY
    default:
      return Region.NORWAY
  }
}

export default getRegionFromLocale
