import { CurrencySymbol, DefaultCurrency, getCurrencyFromLocale } from '@lib/utils/getCurrencyFromLocale'
import { useIntl } from 'react-intl'

const formatNumber = (value: number, currency: DefaultCurrency) => {
  if (currency === DefaultCurrency.NO) {
    return `${CurrencySymbol[currency]} ${value}`
  }
  if (currency === DefaultCurrency.SE) {
    return `${value} ${CurrencySymbol[currency]}`
  }
  return `${value} ${currency}`
}

export default function useTranslateMessage() {
  const intl = useIntl()
  const { locale } = intl
  const localCurrency = getCurrencyFromLocale(locale)

  return {
    t: intl.formatMessage,
    n: (value: number) => formatNumber(value, localCurrency),
    d: intl.formatDate
  }
}
