export enum Locale {
  NORWEGIAN_NORWAY = 'no',
  SWEDISH_SWEDEN = 'se',
  ENGLISH_NORWAY = 'en-no',
  ENGLISH_SWEDEN = 'en-se'
}

export const DefaultLocale: { NORWAY: Locale; SWEDEN: Locale; FALLBACK: Locale } = {
  NORWAY: Locale.NORWEGIAN_NORWAY,
  SWEDEN: Locale.SWEDISH_SWEDEN,
  FALLBACK: Locale.NORWEGIAN_NORWAY
} as const

const currentLocales: Locale[] = [
  Locale.ENGLISH_NORWAY,
  Locale.ENGLISH_SWEDEN,
  Locale.SWEDISH_SWEDEN,
  Locale.NORWEGIAN_NORWAY
]

export const pathNameHasLocale = (pathname: string): boolean => {
  return currentLocales.some((locale) => pathname.concat('/').startsWith(`/${locale}/`))
}

export const extractLocaleFromPathname = (pathname: string): Locale => {
  if (!pathNameHasLocale(pathname)) {
    return DefaultLocale.NORWAY
  }
  const statedLocale = pathname.split('/')
  const matchedLocale = currentLocales.find((locale) => statedLocale.includes(locale))
  return matchedLocale || DefaultLocale.FALLBACK
}

export const removeLocaleFromPathname = (pathname: string | null): string => {
  if (!pathname || !pathNameHasLocale(pathname)) {
    return pathname || ''
  }
  const presentLocale = extractLocaleFromPathname(pathname)
  const copyPath = pathname.replace(`/${presentLocale}`, '')
  return copyPath
}
