import React, { memo } from 'react'
import { BaseImage, BaseImageProps } from '@components/BaseImage'

const DEFAULT_IMAGE = '/img/default-cover.png'

const BookImage = memo((props: Omit<BaseImageProps, 'fallbackImageSrc'>) => (
  <BaseImage {...props} fallbackImageSrc={DEFAULT_IMAGE} />
))

export default BookImage
