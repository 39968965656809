import { UserInterface } from '@hermes/types/src'
import { UserListInterface } from '@hermes/types/src/@types/api-legacy/UserList'
import { getPublicConfigVariable } from '@lib/utils/getConfigVariable'
import { CookieValueTypes } from 'cookies-next'

const apiPath = getPublicConfigVariable('LEGACY_API_URL')

export const fetchMe = async ({
  token,
  country
}: {
  token: CookieValueTypes
  country: string
}): Promise<{
  data: UserInterface
  code?: number
  message?: string
}> => {
  try {
    const response = await fetch(`${apiPath}/user/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
        country
      }
    })

    if (!response.ok) {
      throw new Error('Failed to fetch user')
    }

    return response.json()
  } catch (error) {
    console.error('Error fetching user:', error)
    throw error
  }
}

export const getUserIntercomHash = async ({
  token,
  country
}: {
  token: CookieValueTypes
  country: string
}): Promise<{ data: string }> => {
  try {
    const response = await fetch(`${apiPath}/user/me/intercom-hash`, {
      headers: {
        Authorization: `Bearer ${token}`,
        country
      }
    })

    if (!response.ok) {
      throw new Error('Failed to fetch user hash')
    }

    return response.json()
  } catch (error) {
    console.error('Error fetching user hash:', error)
    throw error
  }
}

export const createUserList = async ({
  token,
  title,
  privateList,
  country
}: {
  token: string
  title: string
  privateList: boolean
  country: string
}): Promise<{
  data: UserListInterface
  code?: number
  message?: string
}> =>
  fetch(`${apiPath}/lists`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      country
    },
    method: 'POST',
    body: JSON.stringify({
      title,
      private: privateList
    })
  }).then((r) => r.json())

export const fetchUserLists = async ({
  token,
  page = 0,
  perPage = 10,
  country
}: {
  token: string
  page?: number
  perPage?: number
  country: string
}): Promise<{
  data: {
    array: UserListInterface[]
  }
  code?: number
  message?: string
}> =>
  fetch(`${apiPath}/lists?page=${page}&perPage=${perPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      country
    }
  }).then((r) => r.json())

export const addBookToWaitingList = async ({
  book,
  type,
  token,
  country
}: {
  book: number
  type: 'not read yet' | 'reading' | 'have read'
  token: string
  country: string
}): Promise<{
  code?: number
  message?: string
}> =>
  fetch(`${apiPath}/api/mybooks/${book}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      country
    },
    method: 'POST',
    body: JSON.stringify({ type })
  }).then((r) => r.json())

export const checkWaitingList = async ({
  token,
  country,
  bookId
}: {
  token: string
  country: string
  bookId: number
}): Promise<{
  data: {
    waitingNotification: boolean
  }
  code?: number
  message?: string
}> =>
  fetch(`${apiPath}/waitinglists/isWaiting/${bookId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      country
    }
  }).then((r) => r.json())

export const addBookToList = ({
  bookId,
  token,
  listId,
  country
}: {
  bookId: number
  token: string
  listId: number
  country: string
}): Promise<{
  code?: number
  message?: string
}> =>
  fetch(`${apiPath}/lists/${listId}/books`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      country
    },
    body: JSON.stringify({ book: bookId })
  }).then((r) => r.json())

export const changePhoneNumber = ({
  token,
  phoneNumber,
  userId,
  country
}: {
  token: CookieValueTypes
  phoneNumber: string
  userId: number
  country: string
}) =>
  fetch(`${apiPath}/user/${userId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      country
    },
    body: JSON.stringify({ phone: phoneNumber })
  }).then((r) => {
    if (!r.ok) {
      throw new Error('Failed to change phone number')
    }
    return r.json()
  })

export const createPayoutMethod = async ({
  token,
  data: { accountNumber, accountHolderName, dateOfBirth, isPrimary },
  country
}: {
  token: CookieValueTypes
  country: string
  data: {
    accountNumber: string
    accountHolderName: string
    dateOfBirth: string
    isPrimary: boolean
  }
}) => {
  const response = await fetch(`${apiPath}/user/me/accounts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      country
    },
    body: JSON.stringify({
      account_number: accountNumber,
      account_holder_name: accountHolderName,
      birthday: dateOfBirth,
      isRoyaltiesPayout: false,
      usage: 'payout',
      type: 'Bank account',
      isDefault: isPrimary
    })
  })

  if (!response.ok) {
    const errorData = await response.json()
    throw new Error(errorData.message || 'Failed to create payout method')
  }

  const res = await response.json()

  return res.data
}
