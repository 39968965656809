import Image, { ImageProps, StaticImageData } from 'next/image'
import { FC, useMemo } from 'react'

export interface BaseImageProps extends Omit<ImageProps, 'src'> {
  src: string | StaticImageData | null | undefined
  fallbackImageSrc: string
  isS3Hosted?: boolean
}

const getImageSource = (
  src: string | StaticImageData | null | undefined,
  fallbackImageSrc: string
): {
  source: string | StaticImageData
  isStaticAsset: boolean
} => {
  const isString = typeof src === 'string'
  const isUrl = isString && src.startsWith('http')
  const isS3Asset = isString && src.startsWith('//')
  const isPublicAsset = isString && src.startsWith('/') && !isUrl && !isS3Asset

  if (isUrl) {
    return { source: src, isStaticAsset: false }
  }
  if (isS3Asset) {
    const urlWithHost = `https:${src}`
    return { source: urlWithHost, isStaticAsset: false }
  }
  if (isPublicAsset) {
    return { source: src, isStaticAsset: true }
  }
  return { source: fallbackImageSrc, isStaticAsset: true }
}

export const BaseImage: FC<BaseImageProps> = ({ fallbackImageSrc, src, alt, ...props }) => {
  const { source } = useMemo(() => getImageSource(src, fallbackImageSrc), [src, fallbackImageSrc])

  return (
    <>
      <Image
        {...props}
        alt={alt || ''}
        src={source}
        unoptimized={true}
        onError={() => {
          if (source !== fallbackImageSrc) {
            BaseImage({ ...props, src: fallbackImageSrc, fallbackImageSrc, alt })
          }
        }}
      />
    </>
  )
}
